import React from 'react';
import styled from 'styled-components';


const Dashboard = () => {
	return ( 
		<Container>
			<Title>Dashboard Page</Title>
		</Container>
	 );
}
 
export default Dashboard;

const Container = styled.div`
display: flex;
flex: 1;
justify-content: center;
align-items: center;
`;

const Title = styled.h1`
margin-top: 50px;
font-size: 50px;
`;