import React from "react"
import { Router } from "@reach/router"
import Login from '../components/adminPages/login.page';
import Dashboard from "../components/adminPages/dashboard.page";

const Admin = (props) => {
	console.log(props);
  return (
   
      <Router basepath="/admin">
        <Login default path="/login" />
		  <Dashboard path="/dashboard/" />
      </Router>
  
  )
}
export default Admin